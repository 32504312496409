var footerAccordion = $('[data-js="footer-acc"]');
var appointmentAccordion = $('[data-js="appointment-accordion"]');
var appointmentForm = $('[data-js="additional-register-form"]');
var accBtn = $('.acc-btn');

accBtn.on('click', function(e) {
    var $this = $(this);
    var $content = appointmentForm.length
        ? $this.siblings('.form-accordion__content')
        : $this.next();

    $this.toggleClass('active');
    $content.slideToggle();
});

$(document).ready(function () {
    var windowWidth = $(window).width();

    // for iPad & smaller devices
    if (windowWidth <= 1024) {
        footerAccordion.on('click', function() {
            var $this = $(this);
            $this.toggleClass('active');
            $this.next().slideToggle(300);
        });
    }

    if (window.matchMedia('(max-width: 767px)').matches) {
        // Ensure first appointment card is expanded by default
        appointmentAccordion.first().closest('.appointment-card').addClass('active');

        if ( $('.appointment-card').hasClass('active') ) {
            $('.appointment-card.active').find('.appointment-card__content, .appointment-landingpage__submit').stop().slideDown(300);
        }

        appointmentAccordion.on('click', function() {
            var $this = $(this).closest('.appointment-card');

            // Check if other appointment cards are expanded, if so, collapse
            if ( $('.appointment-card.active').not($this).length > 0 ) {
                $('.appointment-card.active').find('.appointment-card__content, .appointment-landingpage__submit').stop().slideUp(300);
                $('.appointment-card.active').removeClass('active');
            }

            $this.toggleClass('active');
            $this.find('.appointment-card__content, .appointment-landingpage__submit').stop().slideToggle(300);
        });
    }
});

// click on a faq folder link initializes a scroll-down animation to the correct folder title
$('[data-js="anchor-link"]').on('click', function (e) {
    var windowWidth = $(window).width();
    var $this = $(this);
    var scrollToElementSelector = $this.attr('href');
    // var scrollToElementSelector = $('#afspraken');
    var headerHeight = $('[data-js="page-header"]').height() + 120;

    if (windowWidth < 767) {
        headerHeight -= 50;
    }

    $('html, body').animate({
        scrollTop: $(scrollToElementSelector).offset().top - headerHeight
    }, 1000);
});
